import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  MetricActions,
  MetricState,
  PhoneService,
  PhoneState,
  billingTextsOptIn,
  selectMessage,
  selectOptedIn,
} from '@patient-ui/patient-web/store';
import {
  regexPatterns,
  registrationFormValidationMessages,
} from '@patient-ui/shared/constants';
import {
  BillingNotificationsOptIn,
  EventCode,
  IBadDebtMetric,
  IPhoneValidationResponse,
} from '@patient-ui/shared/models';

@Component({
  selector: 'patient-ui-billing-optin',
  templateUrl: './billing-optin.component.html',
  styleUrls: ['./billing-optin.component.scss'],
})
export class BillingOptinComponent implements OnDestroy, OnInit {
  optInForm!: FormGroup;
  consentProvidedCbChecked = false;
  invalidPhoneMsg$ = new BehaviorSubject<boolean>(false);
  techDifficultiesMsg$ = new BehaviorSubject<boolean>(false);
  lpid = ``;
  campaignId = ``;

  destroyed = new Subject();

  readonly validationMessages = registrationFormValidationMessages;

  optInSuccessful = false;
  optInAlreadyExists = false;

  phone_number = '';

  constructor(
    public authStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private changeDetector: ChangeDetectorRef,
    private builder: FormBuilder,
    private phoneService: PhoneService,
    private phoneStore: Store<PhoneState>,
    private router: Router,
    private route: ActivatedRoute,
    private metricStore: Store<MetricState>
  ) {}

  ngOnInit(): void {
    this.phoneStore.select(selectOptedIn).subscribe((status: boolean) => {
      if (status) {
        this.optInAlreadyExists = status;
      }
    });

    this.phoneStore.select(selectMessage).subscribe((message: string) => {
      if (message === 'successfully ') {
        this.optInAlreadyExists = false;
        this.optInSuccessful = true;
        this.changeDetector.detectChanges();
      }
    });
    this.phoneStore
      .select(selectOptedIn)
      .pipe(takeUntil(this.destroyed))
      .subscribe((optInStatus) => {
        if (optInStatus === true) {
          this.techDifficultiesMsg$.next(true);
        } else {
          this.techDifficultiesMsg$.next(false);
          if (optInStatus === false) {
            this.optInSuccessful = false;
          }
        }
      });

    this.initForm();

    this.lpid = this.route.snapshot.paramMap.get(`lpid`) || ``;
    this.campaignId = this.route.snapshot.paramMap.get(`campaignId`) || ``;
    if (this.lpid && this.campaignId) {
      this.logBadDebtMetric(
        this.lpid,
        this.campaignId,
        EventCode.textOptInLanded,
        undefined
      );
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  initForm() {
    this.optInForm = this.builder.group({
      phone: new FormGroup({
        number: new FormControl('', [
          Validators.pattern(regexPatterns.phone),
          Validators.required,
        ]),
      }),
      terms: [
        '',
        {
          validators: [Validators.required],
        },
      ],
      captcha: [''],
    });
  }

  consentProvidedCbToggle() {
    this.consentProvidedCbChecked = !this.consentProvidedCbChecked;
    this.optInForm.get('terms')?.markAsDirty();
  }

  async onSubmit() {
    this.optInForm.markAsDirty();
    this.optInForm.get('terms')?.markAsDirty();

    const response = await this.validatePhone();

    if (!response.status || response.status.trim().toLowerCase() === `error`) {
      this.techDifficultiesMsg$.next(true);
    } else if (
      response.status.trim().toLowerCase() === 'invalidnumber' ||
      response.type?.trim().toLowerCase() !== `mobile`
    ) {
      this.invalidPhoneMsg$.next(true);
    } else if (!this.consentProvidedCbChecked) {
      return;
    } else {
      this.phone_number = this.optInForm.get(`phone.number`)?.value;
      const optInData: BillingNotificationsOptIn = {
        lpid: this.lpid,
        campaignId: this.campaignId,
        mobileConsent: this.optInForm.controls.terms.valid,
        mobilePhone: this.optInForm.get(`phone.number`)?.value,
      };
      this.phoneStore.dispatch(billingTextsOptIn({ optInData: optInData }));
    }
  }

  register() {
    this.router.navigate([`/account/registration/register`]);
  }

  async signIn() {
    await this.oktaAuth.signInWithRedirect({
      originalUri: '/',
    });
  }

  async validatePhone(): Promise<Partial<IPhoneValidationResponse>> {
    const phoneNumber = this.optInForm.get(`phone.number`)?.value;
    return new Promise((resolve, reject) => {
      if (!phoneNumber) {
        resolve({ status: `invalidnumber` });
      } else if (!this.optInForm.get(`phone.number`)?.valid) {
        resolve({ status: `invalidnumber` });
      } else {
        this.phoneService.validatePhone(phoneNumber).subscribe(
          (response) => {
            resolve(response);
          },
          (_error) => {
            reject({ status: `Error` });
          }
        );
      }
    });
  }

  logBadDebtMetric(
    lpid: string,
    templateId: string,
    eventCode: EventCode,
    mobileNumber?: string
  ) {
    const badDebtMetric: IBadDebtMetric = {
      lpId: lpid,
      eventCode: eventCode,
      templateId: templateId,
      userType: 'Guest',
    };

    if (mobileNumber) {
      badDebtMetric.mobileNumber = mobileNumber;
    }
    this.metricStore.dispatch(
      MetricActions.logBadDebtMetric({ metrics: badDebtMetric })
    );
  }
}
