import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbNavModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgxMaskModule } from 'ngx-mask';

import {
  LoaderInterceptor,
  LoaderService,
  PatientInterceptor,
  PatientWebStoreModule,
} from '@patient-ui/patient-web/store';
import { SharedUiStoreModule } from '@patient-ui/shared-ui/store';
import { SharedUiUiModule } from '@patient-ui/shared-ui/ui';

import { RegistrationEmailVerifyComponent } from './account-registration/containers/registration-email-verify/registration-email-verify.component';
import { BillingOptinComponent } from './billing-optin/billing-optin.component';
import { MfaSetupConfirmationComponent } from './containers/mfa-setup-confirmation/mfa-setup-confirmation.component';
import { PortalFooterComponent } from './containers/portal-footer/portal-footer.component';
import { PortalHeaderComponent } from './containers/portal-header/portal-header.component';
import { PortalLayoutComponent } from './containers/portal-layout/portal-layout.component';
import { PortalTimoutModalComponent } from './containers/portal-timout-modal/portal-timout-modal.component';
import { PublicFooterComponent } from './containers/public-footer/public-footer.component';
import { PublicHeaderComponent } from './containers/public-header/public-header.component';
import { PublicLayoutComponent } from './containers/public-layout/public-layout.component';
import { OktaAuthComponent } from './okta-auth/okta-auth.component';
import { AuthGuard } from './okta-auth/okta-auth.guard';
import { PatientWebFeatureShellRoutingModule } from './patient-web-feature-shell-routing.module';
import { ExternalUrlDirective } from './shared/directives/external-url.directive';
import { PdfViewerComponent } from './shared/pdf-viewer/pdf-viewer.component';

@NgModule({
  declarations: [
    RegistrationEmailVerifyComponent,
    PortalFooterComponent,
    PortalHeaderComponent,
    PortalLayoutComponent,
    PublicFooterComponent,
    PublicHeaderComponent,
    PublicLayoutComponent,
    OktaAuthComponent,
    ExternalUrlDirective,
    PdfViewerComponent,
    PortalTimoutModalComponent,
    BillingOptinComponent,
    MfaSetupConfirmationComponent,
  ],
  imports: [
    CommonModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbNavModule,
    NgIdleKeepaliveModule.forRoot(),
    ReactiveFormsModule,
    PatientWebFeatureShellRoutingModule,
    SharedUiStoreModule,
    PatientWebStoreModule,
    PdfJsViewerModule,
    SharedUiUiModule,
    NgxMaskModule,
  ],
  providers: [
    AuthGuard,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PatientInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PatientWebFeatureShellModule {}
