<div class="w-100 h-100">
  <div class="container-xl flex-column d-flex h-100">
    <div class="row align-center">
      <div class="col">
        <h2 class="opt-in-header mt-6 mb-6">
          {{ !optInAlreadyExists ? 'Sign Up for Text Messages' : 'You are already signed up for text messages' }}
        </h2>
      </div>
    </div>
    <div class="row d-flex flex-fill">
      <div *ngIf="optInAlreadyExists" class="col-sm-12 col-md-7">
        <div class="d-inline-block">
          <p class="d-inline-block">
            We currently send billing updates to you at a phone number ending in <b>{{ phone_number.slice(-4) }}.</b>
          </p>
          <hr class="linea w-100 border-dark" />
        </div>

        <h4 class="mt-4 mb-5">
          Sign in to your Labcorp Patient account
        </h4>
        <p>
          With a Labcorp Patient account, you can view all your bills, pay securely anytime, and save your payment method to make bill pay
          even easier next time. All in one <br />
          convenient location.
        </p>
        <button class="btn btn-min-width btn-primary mb-5" (click)="signIn()">
          Sign In
        </button>
        <p>Don't have a Labcorp Patient account?</p>
        <div class="mb-8">
          <button class="btn btn-outline-primary" (click)="register()">
            Create an Account
          </button>
        </div>
      </div>
      <div class="col-sm-12 col-md-7" *ngIf="!optInAlreadyExists">
        <div id="optin-form" *ngIf="!optInSuccessful">
          <h3 class="mt-0 mb-5">
            It's easy to get billing updates from Labcorp.
          </h3>
          <div class="row">
            <div class="col-sm-12 col-md-10">
              <div id="tech-difficulties" class="alert alert-danger fade show" role="alert" *ngIf="techDifficultiesMsg$ | async">
                <i class="alert-icon material-icons">warning</i>
                <div class="text">
                  We are unable to sign you up at this time. Please try again later.
                </div>
              </div>
            </div>
          </div>
          <form [formGroup]="optInForm" (submit)="onSubmit()">
            <div class="form-row" formGroupName="phone">
              <div class="col-xs-12 col-sm-10 pt-0 pb-4 pr-sm-5 col-md-8 col-lg-6">
                <label for="number">Mobile Number</label>
                <input
                  type="tel"
                  class="form-control"
                  formControlName="number"
                  id="number"
                  matInput
                  mask="000-000-0000"
                  [showMaskTyped]="true"
                  [class.is-invalid]="invalidPhoneMsg$ | async"
                />
                <small id="invalid-number-error" *ngIf="invalidPhoneMsg$ | async" class="form-text invalid-feedback d-block">
                  <i class="material-icons icon-sm">error_outline</i>
                  <span> Please enter a valid mobile number.</span>
                </small>
              </div>
            </div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-10 pt-0 pb-4 pr-sm-6">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="terms"
                    id="terms"
                    (change)="consentProvidedCbToggle()"
                    (blur)="optInForm.controls.terms.markAsDirty()"
                    [class.is-invalid]="!consentProvidedCbChecked && optInForm.get('terms')?.dirty"
                  />
                  <label class="custom-control-label font-14" for="terms">
                    By checking this box, you agree that Labcorp may send you recurring informational messages regarding your lab services.
                    You can reply STOP anytime to cancel future texts or HELP if you need assistance. Message and data rates may apply. Text
                    message frequency will vary. For more information, view our
                    <a href="https://www.labcorp.com/terms-and-conditions" target="_blank"><u>Terms & Conditions</u></a>
                    and
                    <a href="https://www.labcorp.com/hipaa-privacy" target="_blank"><u>Privacy Policy</u></a
                    >.
                  </label>
                  <small *ngIf="!consentProvidedCbChecked && optInForm.get('terms')?.dirty" class="form-text invalid-feedback d-block">
                    <i class="material-icons icon-sm">error_outline</i>
                    Please check the box to continue
                  </small>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-10 pt-3 mb-8 pr-sm-6">
                <button id="submit" type="submit" class="btn btn-min-width btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        <div id="login-prompt" *ngIf="optInSuccessful">
          <h3 class="mt-4 mb-5">
            You're all set. We'll send you a confirmation text shortly.
          </h3>
          <p>
            With a Labcorp Patient account, you can make appointments, review lab results, and pay your bills online, easily and securely.
            All in one convenient location.
          </p>
          <button class="btn btn-min-width btn-primary mb-5" (click)="signIn()">
            Sign In
          </button>
          <p>Don't have a Labcorp Patient account?</p>
          <div class="mb-8">
            <button class="btn btn-outline-primary" (click)="register()">
              Create an Account
            </button>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 d-none d-sm-block position-relative overflow-hidden b-shadow">
        <img loading="lazy" src="assets/images/lifestyle-image.png" class="optin-image" alt="image of person in a car looking at phone" />
      </div>
    </div>
  </div>
</div>
